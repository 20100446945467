import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import 'react-image-lightbox/style.css';
import GlobalStyles from './theme/GlobalStyles';
import { Header } from './components/Header';
import { theme } from './theme';
import { Login } from './page/Login';
import { PanelWrapper } from './page/PanelWrapper';
import { ResetPassword } from './page/ResetPassword';
import { ResetPasswordForm } from './page/ResetPasswordForm';

const App = () => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <Header />
                <Router>
                    <Switch>
                        <Route path="/" exact component={Login} />
                        <Route path="/przypomnij-haslo" exact component={ResetPassword} />
                        <Route path="/reset-hasla/:token" exact component={ResetPasswordForm} />
                        <Route path="*" component={PanelWrapper} />
                    </Switch>
                </Router>
                <NotificationContainer />
            </ThemeProvider>
        </>
    );
};

export default App;
