import React from 'react';
import { Field, Form } from 'react-final-form';
import axios from 'axios';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { NotificationManager } from 'react-notifications';
import { apiUrl } from '../../api';
import { Button, ErrorMessage, StyledH1, TextArea } from '../../components/FormComponents';

const Rigth = styled.div`
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-end;
    width: 400px !important;
    p {
        width: 100% !important;
    }
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    & > div {
        width: 100%;
        display: grid;
        grid-row-gap: 10px;
        margin-right: 10px;
    }
`;

export const AgreementForm = ({ initialValues }) => {
    const token = useSelector((store) => store.token);

    return (
        <Form
            onSubmit={(values) => {
                axios
                    .put(`${apiUrl}/content/${initialValues.id}`, values, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then(() => {
                        NotificationManager.success('Zapisano zmiany');
                    })
                    .catch(() => {
                        NotificationManager.error('spróbuj ponownie', 'Błąd ');
                    });
            }}
            initialValues={initialValues}
            render={({ handleSubmit }) => (
                <StyledForm onSubmit={handleSubmit}>
                    <div>
                        <StyledH1>{initialValues.name}</StyledH1>
                        <Field
                            name="content"
                            render={({ input, meta }) => (
                                <>
                                    <TextArea {...input} placeholder="Treść" rows={20} />
                                    {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                </>
                            )}
                        />
                    </div>
                    <Rigth>
                        <Button>Zapisz</Button>
                        {(initialValues.id > 1 && initialValues.id !== 5 && initialValues.id !== 8 && initialValues.id !== 11 ) && (
                            <>
                                <p>Zmienne:</p>
                                <p>{`<imie>`}</p>
                                <p>{`<nazwisko>`}</p>
                                <p>{`<email>`}</p>
                                <p>{`<telefon>`}</p>
                                <p>{`<nazwaFirmy>`}</p>
                                <p>{`<nip>`}</p>
                                <p>{`<regon>`}</p>
                                <p>{`<pesel>`}</p>
                                <p>{`<numerDowodu>`}</p>
                                <p>{`<dataDowodu>`}</p>
                                <p>{`<ulica>`}</p>
                                <p>{`<miasto>`}</p>
                                <p>{`<kodPocztowy>`}</p>
                                <p>{`<adresKorespondencyjnyUlica>`}</p>
                                <p>{`<adresKorespondencyjnyMiasto>`}</p>
                                <p>{`<adresKorespondencyjnyKodPocztowy>`}</p>
                                <p>{`<aktualnyTelefon>`}</p>
                                <p>{`<operator>`}</p>
                                <p>{`<aktualnaOferta>`}</p>
                                <p>{`<numerPartnera>`}</p>
                                <p>{`<kodPromocyjny>`}</p>
                                <p>{`<nowyNumer>`}</p>
                                <p>{`<produktPromocyjny>`}</p>
                                <p>{`<kategoriaOferty>`}</p>
                                <p>{`<typOferty>`}</p>
                                <p>{`<pakiet>`}</p>
                                <p>{`<opisPakietu>`}</p>
                                <p>{`<wybraneUrzadzenie>`}</p>
                                <p>{`<cenaUrzadzenia>`}</p>
                                <p>{`<numerUmowy>`}</p>
                                <p>{`<ip>`}</p>
                                <p>{`<urzadzenie>`}</p>
                                <p>{`<modelUrzadzenia>`}</p>
                            </>
                        )}
                    </Rigth>
                </StyledForm>
            )}
        />
    );
};
