import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { AcceptanceForm } from './AcceptanceForm';
import { apiUrl } from '../../api';
import { Permission } from '../../hooks/Permission';

export const AcceptanceEdit = () => {
    const match = useRouteMatch();
    const token = useSelector((store) => store.token);
    const [acceptance, setAcceptance] = useState({ obligatory: '1' });

    useEffect(() => {
        axios
            .get(`${apiUrl}/acceptance/${match.params.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { acceptance: newAcceptance } }) => {
                setAcceptance({ ...newAcceptance, obligatory: newAcceptance.obligatory ? '1' : '0' });
            });
    }, [token, match.params.id]);

    return (
        <>
            <Permission permission="acceptance-update" />
            <PanelContentWrapper>
                <AcceptanceForm
                    onSubmit={(values) => {
                        axios
                            .put(`${apiUrl}/acceptance/${match.params.id}`, values, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            })
                            .then(() => {
                                NotificationManager.success('Zapisano zmiany');
                            })
                            .catch(() => {
                                NotificationManager.error('spróbuj ponownie', 'Błąd ');
                            });
                    }}
                    initialValues={acceptance}
                />
            </PanelContentWrapper>
        </>
    );
};
