import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { apiUrl } from '../../api';
import { AgreementForm } from './AgreementForm';
import { Permission } from '../../hooks/Permission';

export const AgreementList = () => {
    const [contents, setContents] = useState([]);
    const token = useSelector((store) => store.token);

    useEffect(() => {
        axios
            .get(`${apiUrl}/content`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { contents: newContents } }) => {
                setContents(newContents);
            });
    }, [token]);

    return (
        <>
            <Permission permission="content-index" />
            <PanelContentWrapper>
                {contents.map((content) => (
                    <AgreementForm initialValues={content} />
                ))}
            </PanelContentWrapper>
        </>
    );
};
