import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { H1 } from './Headings';

export const Input = styled.input`
    border-radius: 1000px;
    font-size: 1.6rem;
    font-weight: 300;
    border: 1px solid ${({ theme: { orange } }) => orange};
    padding: 8px 40px;
    width: 100%;
    &[type='checkbox'],
    &[type='radio'] {
        width: 0;
        height: 0;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        & ~ label {
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            font-weight: 300;
            &::before {
                flex-shrink: 0;
                content: '';
                height: 16px;
                width: 16px;
                display: flex;
                border: 1px solid ${({ theme: { lightOrange } }) => lightOrange};
                background-color: transparent;
                border-radius: 5px;
                margin-right: 10px;
                transition: background-color 0.2s ease-in-out;
            }
        }
        &:checked {
            & ~ label {
                &::before {
                    background-color: ${({ theme: { lightOrange } }) => lightOrange};
                }
            }
        }
    }
`;

export const TextArea = styled.textarea`
    border-radius: 20px;
    font-size: 1.6rem;
    font-weight: 300;
    border: 1px solid ${({ theme: { orange } }) => orange};
    padding: 8px 40px;
    width: 100%;
    resize: none;
`;

export const Button = styled.button`
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 16px 35px;
    border: none;
    color: #fff;
    font-size: 2rem;
    background-color: ${({ theme: { orange, gray }, secondary }) => (secondary ? gray : orange)};
`;

export const ErrorMessage = styled.span`
    font-size: 1.4rem;
    color: ${({ theme: { error } }) => error};
`;

const StyledSearchInput = styled.input`
    border: none;
    border-bottom: 1px solid ${({ theme: { orange } }) => orange};
    width: 100%;
    font-size: 18px;
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    position: absolute;
    right: 15px;
    height: 24px;
    top: -5px;
    color: #636262;
`;
const StyledSearchWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

export const SearchInput = ({ className, ...props }) => {
    return (
        <StyledSearchWrapper className={className}>
            <StyledSearchInput type="text" {...props} />
            <StyledFontAwesomeIcon icon={faSearch} fixedWidth />
        </StyledSearchWrapper>
    );
};

export const StyledForm = styled.form`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    & > div {
        max-width: 400px;
        width: 100%;
        display: grid;
        grid-row-gap: 10px;
    }
`;

export const StyledH1 = styled(H1)`
    margin-bottom: 20px;
`;

export const Select = styled.select`
    font-size: 1.6rem;
    border-radius: 20px;
    border: 1px solid ${({ theme: { orange } }) => orange};
    padding: 7px 40px;
`;

export const LabelButton = styled.label`
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 16px 35px;
    border: none;
    color: #fff;
    font-size: 2rem;
    background-color: ${({ theme: { orange, gray }, secondary }) => (secondary ? gray : orange)};
    position: relative;
    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        padding: 0;
        margin: 0;
        border: none;
    }
`;
