import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { Button, ErrorMessage, Input, StyledH1 } from '../components/FormComponents';
import LoginImage from '../static/images/loginImage.png';
import { apiUrl } from '../api';
import { LoginWrapper, StyledImg, StyledForm } from '../components/LoginComponents';

const StyledInnerForm = styled(StyledForm)`
    min-height: 120px;
`;

export const ResetPassword = () => {
    const [redirect, setRedirect] = useState(null);
    return (
        <>
            {redirect && <Redirect to={redirect} />}
            <LoginWrapper>
                <StyledH1>Reset hasła</StyledH1>
                <Form
                    onSubmit={(values) => {
                        axios
                            .post(`${apiUrl}/resetPassword`, values, {})
                            .then(() => {
                                NotificationManager.success('Sprawdź adres email');
                                setRedirect('/');
                            })
                            .catch(() => {
                                NotificationManager.error('spróbuj ponownie', 'Błąd ');
                            });
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.email) {
                            errors.email = 'Podaj adres email';
                        }
                        return errors;
                    }}
                    render={({ handleSubmit }) => (
                        <StyledInnerForm onSubmit={handleSubmit}>
                            <Field
                                name="email"
                                type="email"
                                render={({ input, meta }) => (
                                    <>
                                        <Input {...input} placeholder="Login" />
                                        {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                    </>
                                )}
                            />
                            <Button>Zresetuj hasło</Button>
                        </StyledInnerForm>
                    )}
                />
                <StyledImg src={LoginImage} alt="" />
            </LoginWrapper>
        </>
    );
};
