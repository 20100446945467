import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { DeviceForm } from './DeviceForm';
import { apiUrl } from '../../api';
import { createFormData } from '../../utils';
import { Permission } from '../../hooks/Permission';

export const DeviceEdit = () => {
    const match = useRouteMatch();
    const token = useSelector((store) => store.token);
    const [device, setDevice] = useState({ specification: [] });

    useEffect(() => {
        axios
            .get(`${apiUrl}/device/${match.params.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { device: newDevice } }) => {
                setDevice(newDevice);
            });
    }, [token, match.params.id]);

    return (
        <>
            <Permission permission="device-update" />
            <PanelContentWrapper>
                <DeviceForm
                    initialValues={device}
                    id={match.params.id}
                    onSubmit={(values, form) => {
                        const fd = new FormData();
                        Object.keys(values).forEach((value) => {
                            createFormData(fd, value, values[value]);
                        });
                        fd.append('_method', 'put');
                        axios
                            .post(`${apiUrl}/device/${match.params.id}`, fd, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            })
                            .then(({ data: { device: newDevice } }) => {
                                form.reset(newDevice);
                                NotificationManager.success('Zapisano zmiany');
                            })
                            .catch(() => {
                                NotificationManager.error('spróbuj ponownie', 'Błąd ');
                            });
                    }}
                />
            </PanelContentWrapper>
        </>
    );
};
