import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { Button, ErrorMessage, Input, StyledForm, StyledH1 } from '../../components/FormComponents';

import PanelContentWrapper from '../../components/PanelContentWrapper';
import { apiUrl } from '../../api';
import { Permission } from '../../hooks/Permission';

export const ApiConfig = () => {
    const token = useSelector((store) => store.token);
    const [config, setConfig] = useState({});

    useEffect(() => {
        axios
            .get(`${apiUrl}/config/1`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { config: newConfig } }) => {
                setConfig(newConfig);
            });
    }, [token]);

    return (
        <>
            <Permission permission="api-index" />
            <PanelContentWrapper>
                <Form
                    onSubmit={(values) => {
                        axios
                            .put(`${apiUrl}/config/1`, values, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            })
                            .then(({ data: { config: newConfig } }) => {
                                setConfig(newConfig);
                                NotificationManager.success('Zapisano zmiany');
                            })
                            .catch(() => {
                                NotificationManager.error('spróbuj ponownie', 'Błąd ');
                            });
                    }}
                    initialValues={config}
                    render={({ handleSubmit }) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <div>
                                <StyledH1>API</StyledH1>
                                <p>Konfiguracja apli logowania partnera FMWorld</p>
                                <Field
                                    name="fmLogin"
                                    render={({ input, meta }) => (
                                        <>
                                            <Input {...input} placeholder="FM Login" />
                                            {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                        </>
                                    )}
                                />
                                <Field
                                    name="fmPassword"
                                    render={({ input, meta }) => (
                                        <>
                                            <Input {...input} placeholder="FM Hasło" />
                                            {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                        </>
                                    )}
                                />
                                {/*       <Field
                            name="smsApiToken"
                            render={({ input, meta }) => (
                                <>
                                    <Input {...input} placeholder="SMS Api Token" />
                                    {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                </>
                            )}
                        /> */}
                            </div>

                            <Button>Zapisz</Button>
                        </StyledForm>
                    )}
                />
            </PanelContentWrapper>
        </>
    );
};
