import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Button, ErrorMessage, Input, Select, StyledForm, StyledH1 } from '../../components/FormComponents';
import { apiUrl } from '../../api';

export const PromoProductForm = ({ onSubmit, initialValues }) => {
    const [categories, setCategories] = useState([]);
    const token = useSelector((store) => store.token);

    useEffect(() => {
        axios
            .get(`${apiUrl}/promoCategory/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { categories: newCategories } }) => {
                setCategories(newCategories);
            });
    }, [token]);
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit }) => (
                <StyledForm onSubmit={handleSubmit}>
                    <div>
                        <StyledH1>
                            {initialValues.id ? 'Edytuj produkt promocyjny' : 'Dodaj produkt promocyjny'}
                        </StyledH1>
                        <Field
                            name="name"
                            render={({ input, meta }) => (
                                <>
                                    <Input {...input} placeholder="Nazwa produktu" />
                                    {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                </>
                            )}
                        />
                        <Field
                            name="promoCategoryId"
                            render={({ input, meta }) => (
                                <>
                                    <Select {...input}>
                                        <option value="">Kategoria</option>
                                        {categories.map(({ id, name }) => (
                                            <option value={id}>{name}</option>
                                        ))}
                                    </Select>
                                    {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                </>
                            )}
                        />
                    </div>
                    <Button>Zapisz</Button>
                </StyledForm>
            )}
        />
    );
};
