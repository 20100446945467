import React from 'react';
import styled from 'styled-components';

const StyledModal = styled.div`
    text-align: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    & > div {
        background: #fff;
        max-width: 90%;
        width: 600px;
        padding: 20px;
        position: relative;
    }
`;
const CloseButton = styled.button`
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    width: 20px;
    height: 20px;
    &::before,
    &::after {
        content: '';
        display: block;
        width: 2px;
        height: 20px;
        background: #000;
        transform: rotate(45deg) translateY(-2px);
    }
    &::after {
        transform: rotate(-45deg) translateY(-1px);
    }
`;
export const Modal = ({ closeModal, children, ...props }) => {
    return (
        <StyledModal {...props}>
            <div>
                <CloseButton type="button" onClick={closeModal} />
                {children}
            </div>
        </StyledModal>
    );
};
export const ModalButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 40px auto 20px;
`;
