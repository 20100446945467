import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { Button, ErrorMessage, Input, StyledForm, StyledH1 } from '../../components/FormComponents';
import { apiUrl } from '../../api';
import { Permission } from '../../hooks/Permission';

export const PromoCategoryCreate = () => {
    const token = useSelector((store) => store.token);
    const [redirect, setRedirect] = useState(null);

    return (
        <>
            <Permission permission="promoCategory-store" />
            {redirect && <Redirect to={redirect} />}
            <PanelContentWrapper>
                <Form
                    onSubmit={(values) => {
                        // promoCategory
                        axios
                            .post(`${apiUrl}/promoCategory`, values, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            })
                            .then(() => {
                                setRedirect(`/produkty-promocyjne/`);
                                NotificationManager.success('Dodano kategorię');
                            })
                            .catch(() => {
                                NotificationManager.error('spróbuj ponownie', 'Błąd ');
                            });
                    }}
                    render={({ handleSubmit }) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <div>
                                <StyledH1>Dodaj nową kategorię</StyledH1>
                                <Field
                                    name="name"
                                    render={({ input, meta }) => (
                                        <>
                                            <Input {...input} placeholder="Nazwa kategorii" />
                                            {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                        </>
                                    )}
                                />
                            </div>
                            <Button>Zapisz</Button>
                        </StyledForm>
                    )}
                />
            </PanelContentWrapper>
        </>
    );
};
