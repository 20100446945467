import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { NotificationManager } from 'react-notifications';
import Lightbox from 'react-image-lightbox';
import ReactHtmlParser from 'react-html-parser';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { H1 } from '../../components/Headings';
import { apiUrl } from '../../api';
import { Button, ErrorMessage, Input, Select } from '../../components/FormComponents';
import { Permission } from '../../hooks/Permission';

const Tabs = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    grid-gap: 10px;
`;

const Tab = styled.button`
    display: flex;
    background: ${({ active, theme: { gray, orange } }) => (active ? orange : gray)};
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px 0;
    border: none;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-size: 16px;
    color: #fff;
    font-weight: 700;
`;
const TabContent = styled.div``;

const StyledClient = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
`;
const StyledPanelContentWrapper = styled(PanelContentWrapper)`
    p {
        margin: 10px auto;
    }
`;

const StyledForm = styled.form`
    display: grid;
    flex-direction: row;
    align-items: center;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
`;

const StyledButton = styled(Button)`
    padding: 8px 20px;
`;

const ImageButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
`;

const renderContent = (content) => content && <div>{ReactHtmlParser(content)}</div>;

const renderTextContent = (content) => {
    return (
        content && (
            <div>
                {content.split(/\r?\n/).map((str) => (
                    <p>{str}</p>
                ))}
            </div>
        )
    );
};

export const Contract = () => {
    const [tab, setTab] = useState(1);
    const [contract, setContract] = useState({});
    const match = useRouteMatch();
    const token = useSelector((store) => store.token);
    const [isOpen, setIsOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [photoIndex, setPhotoIndex] = useState(0);

    useEffect(() => {
        axios
            .get(`${apiUrl}/contract/${match.params.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { contract: newContract } }) => {
                setContract(newContract);
                setImages([newContract.idPhotoUrl, newContract.idPhotoBackUrl, newContract.facePhotoUrl]);
            });
    }, [token, match.params.id]);

    return (
        <>
            <Permission permission="contract-update" />
            <StyledPanelContentWrapper>
                <H1>
                    {contract.name} {contract.surname} / {contract.offerType} /{' '}
                    {contract.isNewNumber ? 'Nowy numer' : 'Przeniesienie numeru'}
                </H1>
                <p>Ofera: {contract.offerType ?? 'nie dotyczy'}</p>
                <p>Nowy numer: {contract.isNewNumber ?? 'nie dotyczy'}</p>
                <p>Partner biznesowy: {contract.isCompany ?? 'nie dotyczy'}</p>
                <p>Taryfa: {contract.tariff ?? 'nie dotyczy'}</p>
                <p>Pakiet: {contract.package ?? 'nie dotyczy'}</p>
                <p>Abonament: {contract.packageDescription ?? 'nie dotyczy'}</p>
                <p>Kod promocyjny: {contract.promoCode ?? 'nie dotyczy'}</p>
                <p>Wybór produktu: {contract.promoItem ?? 'nie dotyczy'}</p>
                <p>Wybrany numer: {contract.newNumber ?? 'nie dotyczy'}</p>
                <p>Numer telefonu do przeniesienia: {contract.currentPhone ?? 'nie dotyczy'}</p>
                <p>Numer u operatora: {contract.currentPhoneOperator ?? 'nie dotyczy'}</p>
                <p>Rodzaj oferty u obecnego operatora: {contract.currentPhoneOffer ?? 'nie dotyczy'}</p>
                <p>Tryb przeniesienia numeru: {contract.currentPhoneState ?? 'nie dotyczy'}</p>
                <p>Data przeniesienia numeru: {contract.currentPhoneDate ?? 'nie dotyczy'}</p>
                <p>Zgody: Regulamin / Polityka prywatności / Zgoda marketingowa</p>

                <p>KLIENT</p>
                <StyledClient>
                    <div>
                        <p>Imię: {contract.name ?? 'nie dotyczy'}</p>
                        <p>Nazwisko: {contract.surname ?? 'nie dotyczy'}</p>
                        <p>E-mail: {contract.email ?? 'nie dotyczy'}</p>
                        <p>Telefon: {contract.phone ?? 'nie dotyczy'}</p>
                        <p>Nazwa firmy: {contract.companyName ?? 'nie dotyczy'}</p>
                        <p>NIP: {contract.nip ?? 'nie dotyczy'}</p>
                        <p>REGON: {contract.regon ?? 'nie dotyczy'}</p>
                    </div>
                    <div>
                        <p>PESEL: {contract.pesel ?? 'nie dotyczy'}</p>
                        <p>Numer dowodu: {contract.idNumber ?? 'nie dotyczy'}</p>
                        <p>Data ważności dowodu: {contract.idDate ?? 'nie dotyczy'}</p>
                        <p>Ulica i numer lokalu: {contract.street ?? 'nie dotyczy'}</p>
                        <p>Miejscowość: {contract.city ?? 'nie dotyczy'}</p>
                        <p>Kod pocztowy: {contract.postCode ?? 'nie dotyczy'}</p>
                    </div>
                    <div>
                        <ImageButton onClick={() => setIsOpen(true)}>
                            <img src={contract.idPhotoUrl} alt="" width="250" />
                        </ImageButton>
                        <ImageButton onClick={() => setIsOpen(true)}>
                            <img src={contract.idPhotoBackUrl} alt="" width="250" />
                        </ImageButton>
                    </div>
                    <div>
                        <ImageButton onClick={() => setIsOpen(true)}>
                            <img src={contract.facePhotoUrl} alt="" width="250" />
                        </ImageButton>
                    </div>
                </StyledClient>

                <Tabs>
                    <Tab
                        active={tab === 1}
                        onClick={() => {
                            setTab(1);
                        }}
                    >
                        Treść umowy
                    </Tab>
                    <Tab
                        active={tab === 2}
                        onClick={() => {
                            setTab(2);
                        }}
                    >
                        E-mail
                    </Tab>
                    <Tab
                        active={tab === 5}
                        onClick={() => {
                            setTab(5);
                        }}
                    >
                        PDF
                    </Tab>
                    <Tab
                        active={tab === 6}
                        onClick={() => {
                            setTab(6);
                        }}
                    >
                        Zgody
                    </Tab>
                </Tabs>
                <TabContent>
                    {tab === 1 && <>{renderTextContent(contract.contractContent)}</>}
                    {tab === 2 && <>{renderContent(contract.emailContent)}</>}
                    {tab === 3 && <>{contract.emailContent2}</>}
                    {tab === 4 && <>{contract.emailContent3}</>}
                    {tab === 5 && (
                        <>
                            {contract.pdf !== null && (
                                <Link target="_blank" to={{ pathname: contract.pdf.pdfUrl }}>
                                    {`${contract.pdf.path} (${contract.pdf.updated_at})`}
                                </Link>
                            )}
                        </>
                    )}
                    {tab === 6 && (
                        <>
                            <ul>
                                {contract.acceptances.map(({ content, accepted }) => (
                                    <li>
                                        {content} <strong>{accepted ? 'Tak' : 'Nie'}</strong>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </TabContent>

                <StyledClient>
                    <div>
                        <p>Data: {contract.date ?? 'nie dotyczy'}</p>
                        <p>Godzina: {contract.time ?? 'nie dotyczy'}</p>
                        <p>Urządzenie: {contract.device ?? 'nie dotyczy'}</p>
                    </div>
                    <div>
                        <p>Model urządzenia: {contract.deviceModel ?? 'nie dotyczy'}</p>
                        <p>Formularz wysłano z IP: {contract.ip ?? 'nie dotyczy'}</p>
                        <p>Kod SMS: {contract.smsCode ?? 'nie dotyczy'}</p>
                    </div>
                </StyledClient>

                <Form
                    onSubmit={(values, form) => {
                        axios
                            .put(`${apiUrl}/contract/${match.params.id}`, values, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            })
                            .then(({ data: { contract: newContract } }) => {
                                form.reset(newContract);
                                NotificationManager.success('Zapisano zmiany');
                            })
                            .catch(() => {
                                NotificationManager.error('spróbuj ponownie', 'Błąd ');
                            });
                    }}
                    initialValues={contract}
                    render={({ handleSubmit, values }) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <Field
                                name="status"
                                render={({ input, meta }) => (
                                    <>
                                        <Select {...input}>
                                            <option value="">Status</option>
                                            <option value="NOWA">NOWA</option>
                                            <option value="Rozpatrzona pozytywnie">Rozpatrzona pozytywnie</option>
                                            <option value="Rozpatrzona negatywnie">Rozpatrzona negatywnie</option>
                                            <option value="Wprowadzona">Wprowadzona</option>
                                            <option value="Zrealizowana">Zrealizowana</option>
                                        </Select>
                                        {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                    </>
                                )}
                            />
                            {values.status === 'Wprowadzona' && (
                                <>
                                    <Field
                                        name="contractNumber"
                                        render={({ input, meta }) => (
                                            <>
                                                <Input {...input} placeholder="Numer umowy" />
                                                {meta.error && meta.touched && (
                                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                                )}
                                            </>
                                        )}
                                    />
                                </>
                            )}
                            <StyledButton>Zapisz</StyledButton>
                        </StyledForm>
                    )}
                />
            </StyledPanelContentWrapper>
            {isOpen && (
                <>
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                    />
                </>
            )}
        </>
    );
};
