import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';
import axios from 'axios';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Button, ErrorMessage, Input, LabelButton, Select, StyledH1, TextArea } from '../../components/FormComponents';
import { apiUrl } from '../../api';

const StyledForm = styled.form`
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: flex-end;
    grid-gap: 10px;
    > ${Button} {
        grid-column: 2;
        grid-row: 1;
        margin: auto 0 auto auto;
    }
    > ${LabelButton} {
        margin: auto auto auto 0;
    }
    > * {
        grid-column: 1;
    }
`;
const StyledSpecificationWrapper = styled.div`
    background-color: ${({ theme: { menuBackground } }) => menuBackground};
    grid-column: 1/3;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
`;
const StyledSpecification = styled.div`
    display: grid;
    grid-template-columns: auto 1fr 3fr auto;
    grid-gap: 10px;
    align-items: center;
    width: 100%;
    padding: 10px 0;
`;
const FontAwesomeIconPlus = styled(FontAwesomeIcon)`
    color: ${({ theme: { orange } }) => orange};
`;

export const DeviceForm = ({ onSubmit, initialValues, id }) => {
    const [types, setTypes] = useState([]);
    const token = useSelector((store) => store.token);

    useEffect(() => {
        axios
            .get(`${apiUrl}/deviceType`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { types: newTypes } }) => {
                setTypes(newTypes);
            });
    }, [token]);
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            mutators={{
                // potentially other mutators could be merged here
                ...arrayMutators,
            }}
            render={({ handleSubmit, values, form }) => (
                <StyledForm onSubmit={handleSubmit}>
                    <StyledH1>{id ? 'Edytuj urządzenie' : 'Dodaj urządzenie'}</StyledH1>
                    <Field
                        name="name"
                        render={({ input, meta }) => (
                            <>
                                <Input {...input} placeholder="Nazwa" />
                                {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                            </>
                        )}
                    />

                    <Field
                        name="typeId"
                        render={({ input, meta }) => (
                            <>
                                <Select {...input}>
                                    <option value="">Typ</option>
                                    {types.map(({ id: typeId, name }) => (
                                        <option value={typeId}>{name}</option>
                                    ))}
                                </Select>
                                {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                            </>
                        )}
                    />

                    <p>Specyfikacja</p>
                    <FieldArray name="specification">
                        {({ fields }) => (
                            <StyledSpecificationWrapper>
                                {fields.map((name, index) => (
                                    <StyledSpecification key={name}>
                                        <FontAwesomeIcon onClick={() => fields.remove(index)} icon={faTimes} />
                                        <Field
                                            name={`${name}.name`}
                                            render={({ input, meta }) => (
                                                <>
                                                    <Input {...input} placeholder="Nazwa" />
                                                    {meta.error && meta.touched && (
                                                        <ErrorMessage>{meta.error}</ErrorMessage>
                                                    )}
                                                </>
                                            )}
                                        />
                                        <Field
                                            name={`${name}.content`}
                                            render={({ input, meta }) => (
                                                <>
                                                    <Input {...input} placeholder="Opis" />
                                                    {meta.error && meta.touched && (
                                                        <ErrorMessage>{meta.error}</ErrorMessage>
                                                    )}
                                                </>
                                            )}
                                        />
                                        <FontAwesomeIcon onClick={() => fields.remove(index)} icon={faTimes} />
                                    </StyledSpecification>
                                ))}

                                <FontAwesomeIconPlus
                                    onClick={() => fields.push({ firstName: '', lastName: '' })}
                                    icon={faPlus}
                                />
                            </StyledSpecificationWrapper>
                        )}
                    </FieldArray>

                    <Field
                        name="content"
                        render={({ input, meta }) => (
                            <>
                                <TextArea {...input} placeholder="Opis" rows={20} />
                                {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                            </>
                        )}
                    />

                    <Field
                        name="photo_path"
                        type="file"
                        render={({ input, meta }) => (
                            <>
                                <LabelButton>
                                    {values.photo ? values.photo.name : 'Dodaj zdjęcie'}
                                    <Input
                                        {...input}
                                        onChange={(e) => {
                                            input.onChange(e);
                                            form.change('photo', e.target.files[0]);
                                        }}
                                    />
                                </LabelButton>

                                {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                            </>
                        )}
                    />

                    {values.photoUrl && (
                        <>
                            <img width="300" src={values.photoUrl} alt="" />
                        </>
                    )}

                    <Button>Zapisz</Button>
                </StyledForm>
            )}
        />
    );
};
