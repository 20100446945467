import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { apiUrl } from '../../api';
import { Button, ErrorMessage, StyledForm, StyledH1, TextArea } from '../../components/FormComponents';
import { Permission } from '../../hooks/Permission';

export const PhoneNumberCreate = () => {
    const token = useSelector((store) => store.token);
    const [createdReport, setCreatedReport] = useState({ errors: [] });

    return (
        <>
            <Permission permission="phoneNumber-store" />
            <PanelContentWrapper>
                <Form
                    onSubmit={(values) => {
                        // promoCategory
                        axios
                            .post(`${apiUrl}/phoneNumber`, values, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            })
                            .then(({ data }) => {
                                setCreatedReport(data);
                                NotificationManager.success('Dodano numery telefonów');
                            })
                            .catch(() => {
                                NotificationManager.error('spróbuj ponownie', 'Błąd ');
                            });
                    }}
                    render={({ handleSubmit }) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <div>
                                <StyledH1>Dodaj numery telefonów</StyledH1>
                                <Field
                                    name="content"
                                    render={({ input, meta }) => (
                                        <>
                                            <TextArea
                                                {...input}
                                                placeholder="Wpisz numery - jeden pod drugim"
                                                rows={20}
                                            />
                                            {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                        </>
                                    )}
                                />
                            </div>
                            {!!createdReport.count && (
                                <div>
                                    <p>Ilość rekordów wysłanych: {createdReport.count}</p>
                                    <p>Dodano: {createdReport.created}</p>
                                    <p>
                                        Błędne dane:{' '}
                                        {createdReport.errors.map((error) => (
                                            <span>{error}, </span>
                                        ))}
                                    </p>
                                </div>
                            )}
                            <Button>Zapisz</Button>
                        </StyledForm>
                    )}
                />
            </PanelContentWrapper>
        </>
    );
};
