import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleUp, faTrash} from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Pagination from '@material-ui/lab/Pagination';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Table } from '../../components/Table';
import { StyledLink } from '../../components/Buttons';
import {Button, Input, SearchInput, Select} from '../../components/FormComponents';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { apiUrl } from '../../api';
import { Modal, ModalButtons } from '../../components/Modal';
import useDebounce from '../../hooks/debounce';
import { Permission } from '../../hooks/Permission';
import {Field, Form} from "react-final-form";

const StyledTopWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    input {
        margin-right: 15px;
    }
    select {
        margin-right: 15px;
    }
`;

export const PhoneNumberList = () => {
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [modalId, setModalId] = useState(null);
    const [modal, setModal] = useState(null);
    const [change, setChange] = useState(0);
    const [items, setItems] = useState([]);
    const [isUsed, setIsUsed] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 1000, setPage);

    const token = useSelector((store) => store.token);

    useEffect(() => {
        axios
            .get(`${apiUrl}/phoneNumber`, {
                params: {
                    page,
                    isUsed,
                    search: debouncedSearchTerm,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { phoneNumbers, lastPage } }) => {
                setItems(phoneNumbers);
                setPages(lastPage);
            });
    }, [token, page, change, isUsed, debouncedSearchTerm]);

    return (
        <>
            <Permission permission="phoneNumber-index" />
            <PanelContentWrapper>
                <StyledTopWrapper>
                    <Select
                        name="isUsed"
                        onChange={(e) => {
                            setIsUsed(e.target.value);
                        }}
                    >
                        <option value="">Status</option>
                        <option value="1">Wykorzystane</option>
                        <option value="0">Niewykorzystane</option>
                    </Select>
                    <SearchInput onChange={(e) => setSearchTerm(e.target.value)} />
                    <StyledLink to="/numery-telefonow/dodaj">Dodaj numer</StyledLink>
                </StyledTopWrapper>

                <Form
                    onSubmit={(values) => {
                        axios
                            .delete(`${apiUrl}/phoneNumber/massDestroy`, {
                                params: values,
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            })
                            .then(() => {
                                setChange(change + 1);
                                setModal(null);
                            });
                    }}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Button type="button" onClick={() => {
                                setModal(true);
                            }}>
                                Usuń zaznaczone</Button>
                            <Table>
                                <thead>
                                <tr>
                                    <th />
                                    <th>Numer telefonu</th>
                                    <th>Status</th>
                                    <th />
                                </tr>
                                </thead>
                                <tbody>
                                {items.map(({ id, number, status }) => (
                                    <tr key={id}>
                                        <td>
                                            <Field
                                                name="ids"
                                                type="checkbox"
                                                value={id}
                                                render={({ input }) => (
                                                    <>
                                                        <Input {...input} id={`ids-${id}`} />
                                                        <label htmlFor={`ids-${id}`} />
                                                    </>
                                                )}
                                            />
                                        </td>
                                        <td>{number}</td>
                                        <td>{status}</td>
                                        <td>
                                            <FontAwesomeIcon
                                                onClick={() => {
                                                    setModalId(id);
                                                }}
                                                icon={faTrash}
                                                fixedWidth
                                            />
                                            <Link to={`/numery-telefonow/${id}`}>
                                                <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            <Pagination
                                count={pages}
                                page={page}
                                size="large"
                                shape="rounded"
                                onChange={(event, pageNumber) => {
                                    setPage(pageNumber);
                                }}
                            />
                            {modal && (
                                <Modal
                                    closeModal={() => {
                                        setModal(null);
                                    }}
                                >
                                    <p>Czy na pewno usunąć zaznaczone numery telefonów?</p>
                                    <ModalButtons>
                                        <Button
                                           type="submit"
                                        >
                                            Tak
                                        </Button>
                                        <Button
                                            secondary
                                            onClick={() => {
                                                setModal(null);
                                            }}
                                        >
                                            Nie
                                        </Button>
                                    </ModalButtons>
                                </Modal>
                            )}
                        </form>
                    )}
                />
            </PanelContentWrapper>

            {modalId && (
                <Modal
                    closeModal={() => {
                        setModalId(null);
                    }}
                >
                    <p>Czy na pewno usunąć numer telefonu?</p>
                    <ModalButtons>
                        <Button
                            onClick={() => {
                                axios
                                    .delete(`${apiUrl}/phoneNumber/${modalId}`, {
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                        },
                                    })
                                    .then(() => {
                                        setChange(change + 1);
                                        setModalId(null);
                                    });
                            }}
                        >
                            Tak
                        </Button>
                        <Button
                            secondary
                            onClick={() => {
                                setModalId(null);
                            }}
                        >
                            Nie
                        </Button>
                    </ModalButtons>
                </Modal>
            )}
        </>
    );
};
