import React from 'react';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components';
import { Button, ErrorMessage, Input, StyledForm, StyledH1, TextArea } from '../../components/FormComponents';

const CheckBoxes = styled.div`
    display: flex;
    & > div {
        margin-right: 20px;
    }
`;

export const AcceptanceForm = ({ onSubmit, initialValues }) => {
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit }) => (
                <StyledForm onSubmit={handleSubmit}>
                    <div>
                        <StyledH1>{initialValues.id ? 'Edytuj treść zgody' : 'Dodaj treść zgody'}</StyledH1>
                        <Field
                            name="name"
                            render={({ input, meta }) => (
                                <>
                                    <Input {...input} placeholder="Nazwa" />
                                    {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                </>
                            )}
                        />
                        <CheckBoxes>
                            <div>
                                <Field
                                    name="obligatory"
                                    type="radio"
                                    value="1"
                                    render={({ input }) => (
                                        <>
                                            <Input {...input} id={`${input.name}-y`} />
                                            <label htmlFor={`${input.name}-y`}>Obowiązkowa</label>
                                        </>
                                    )}
                                />
                            </div>

                            <div>
                                <Field
                                    name="obligatory"
                                    type="radio"
                                    value="0"
                                    render={({ input }) => (
                                        <>
                                            <Input {...input} id={`${input.name}-n`} />
                                            <label htmlFor={`${input.name}-n`}>Nieobowiązkowa</label>
                                        </>
                                    )}
                                />
                            </div>
                        </CheckBoxes>
                        <Field
                            name="content"
                            render={({ input, meta }) => (
                                <>
                                    <TextArea {...input} placeholder="Treść" rows={20} />
                                    {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                </>
                            )}
                        />
                    </div>
                    <Button>Zapisz</Button>
                </StyledForm>
            )}
        />
    );
};
