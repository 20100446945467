import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Field, Form } from 'react-final-form';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoginImage from '../static/images/loginImage.png';
import { Button, ErrorMessage, Input, StyledH1 } from '../components/FormComponents';
import { LOGIN } from '../actions';
import { apiUrl } from '../api';
import { CheckBoxAndLink, LoginWrapper, StyledImg, StyledForm } from '../components/LoginComponents';

export const Login = () => {
    const [redirect, setRedirect] = useState(null);
    const dispatch = useDispatch();
    const token = useSelector((store) => store.token);

    useEffect(() => {
        if (token) {
            setRedirect('/dashboard');
        }
    }, [token]);

    const login = async (values) => {
        const response = await axios.post(`${apiUrl}/login`, values, {});
        if (response.data.access_token) {
            const { access_token: newToken, expires_in: expiresIn, role, permissions } = response.data;
            dispatch({
                type: LOGIN,
                payload: { token: newToken, role, permissions, expiresIn: new Date().getTime() + expiresIn * 1000 },
            });
        }
        return true;
    };

    return (
        <>
            {redirect && <Redirect to={redirect} />}
            <LoginWrapper>
                <StyledH1>Logowanie</StyledH1>
                <Form
                    onSubmit={login}
                    validate={(values) => {
                        const errors = {};
                        if (!values.email) {
                            errors.email = 'Podaj adres email';
                        }
                        if (!values.password) {
                            errors.password = 'Podaj hasło';
                        }
                        return errors;
                    }}
                    render={({ handleSubmit }) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <Field
                                name="email"
                                type="email"
                                render={({ input, meta }) => (
                                    <>
                                        <Input {...input} placeholder="Login" />
                                        {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                    </>
                                )}
                            />
                            <Field
                                name="password"
                                type="password"
                                render={({ input, meta }) => (
                                    <>
                                        <Input {...input} placeholder="Hasło" />
                                        {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                    </>
                                )}
                            />
                            <CheckBoxAndLink>
                                <Field
                                    name="rememberMe"
                                    type="checkbox"
                                    render={({ input }) => (
                                        <>
                                            <Input {...input} id={input.name} placeholder="Hasło" />
                                            <label htmlFor={input.name}>Zapamiętaj mnie</label>
                                        </>
                                    )}
                                />

                                <Link to="/przypomnij-haslo">Przypomnij hasło</Link>
                            </CheckBoxAndLink>
                            <Button>Zaloguj</Button>
                        </StyledForm>
                    )}
                />
                <StyledImg src={LoginImage} alt="" />
            </LoginWrapper>
        </>
    );
};
