import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { apiUrl } from '../../api';
import { Table } from '../../components/Table';
import { SearchInput } from '../../components/FormComponents';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { Permission } from '../../hooks/Permission';

const StyledTopWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    input {
        margin-right: 15px;
    }
`;

export const OfferList = () => {
    const token = useSelector((store) => store.token);

    const [types, setTypes] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        axios
            .get(`${apiUrl}/offerCategory`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { categories: newCategories } }) => {
                setCategories(newCategories);
            });
        axios
            .get(`${apiUrl}/offerType`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { types: newTypes } }) => {
                setTypes(newTypes);
            });
    }, [token]);

    return (
        <>
            <Permission permission="offer-index" />
            <PanelContentWrapper>
                <StyledTopWrapper>
                    <SearchInput />
                </StyledTopWrapper>
                <Table>
                    <thead>
                        <tr>
                            <th>Kategoria</th>
                            <th>Typ</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {categories.map(({ id, name }) => (
                            <>
                                {types.map(({ id: typeId, name: typeName }) => (
                                    <tr key={`${id}-${typeId}`}>
                                        <td>{name}</td>
                                        <td>{typeName}</td>
                                        <td>
                                            <Link to={`/oferty/edytuj/${id}/${typeId}`}>
                                                <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </>
                        ))}
                    </tbody>
                </Table>
            </PanelContentWrapper>
        </>
    );
};
