export const theme = {
    orange: '#D29656',
    lightOrange: '#F4D2AF',
    gray: '#939292',
    error: '#ff0000',
    menuBackground: '#FDFAF7',
    font: {
        size: {
            xxxs: '.8em',
            xxs: '.9em',
            xs: '1em',
            s: '1.2em',
            m: '1.4em',
            l: '2em',
            xl: '4em',
            xxl: '8em',
        },
    },
};
