import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { Button, ErrorMessage, Input, LabelButton, Select, StyledH1, TextArea } from '../../components/FormComponents';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { apiUrl } from '../../api';
import { Permission } from '../../hooks/Permission';

const StyledForm = styled.form`
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: flex-end;
    grid-gap: 10px;
    > ${Button} {
        grid-column: 2;
        grid-row: 1;
        margin: auto 0 auto auto;
    }
    > ${LabelButton} {
        margin: auto auto auto 0;
    }
    > * {
        grid-column: 1;
    }
`;

const CheckBoxes = styled.div`
    display: flex;
    & > div {
        margin-right: 20px;
    }
`;

const Dates = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
`;

const StyledDevicesWrapper = styled.div`
    background-color: ${({ theme: { menuBackground } }) => menuBackground};
    grid-column: 1/3;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
    > div {
        width: 100%;
        display: flex;
        margin-bottom: 10px;
        select {
            margin-right: 20px;
        }
    }
`;

const FontAwesomeIconPlus = styled(FontAwesomeIcon)`
    color: ${({ theme: { orange } }) => orange};
`;

const PromoItem = ({ categories, name }) => {
    const token = useSelector((store) => store.token);
    const [categoryId, setCategoryId] = useState(null);
    const [items, setItems] = useState([]);

    useEffect(() => {
        setItems([]);

        if (categoryId) {
            axios
                .get(`${apiUrl}/promoItem/all`, {
                    params: {
                        categoryId,
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(({ data: { items: newItems } }) => {
                    setItems(newItems);
                });
        }
    }, [categoryId, token]);

    return (
        <div>
            <Field
                name={`${name}.categoryId`}
                render={({ input, meta }) => (
                    <>
                        <Select
                            {...input}
                            onChange={(e) => {
                                input.onChange(e);
                                setCategoryId(e.target.value);
                            }}
                        >
                            <option value="">Kategoria</option>
                            {categories.map(({ id, name: categoryName }) => (
                                <option value={id}>{categoryName}</option>
                            ))}
                        </Select>
                        {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                    </>
                )}
            />

            <Field
                name={`${name}.itemId`}
                render={({ input, meta }) => (
                    <>
                        <Select {...input}>
                            <option value="">Produkt</option>
                            {items.map(({ id, name: itemName }) => (
                                <option value={id}>{itemName}</option>
                            ))}
                        </Select>
                        {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                    </>
                )}
            />
        </div>
    );
};

export const PromoCodeCreate = () => {
    const token = useSelector((store) => store.token);
    const [redirect, setRedirect] = useState(null);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        axios
            .get(`${apiUrl}/promoCategory`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { categories: newCategories } }) => {
                setCategories(newCategories);
            });
    }, [token]);

    return (
        <>
            <Permission permission="promoCode-store" />
            {redirect && <Redirect to={redirect} />}
            <PanelContentWrapper>
                <Form
                    mutators={{
                        // potentially other mutators could be merged here
                        ...arrayMutators,
                    }}
                    onSubmit={(values) => {
                        console.log(values);
                        axios
                            .post(`${apiUrl}/promoCode`, values, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            })
                            .then(() => {
                                setRedirect('/kody-promocyjne');
                                NotificationManager.success('Dodano kody promocyjne');
                            })
                            .catch(() => {
                                NotificationManager.error('spróbuj ponownie', 'Błąd ');
                            });
                    }}
                    initialValues={{
                        isReusable: '0',
                        isInfinite: '0',
                        isAllProducts: '1',
                    }}
                    render={({ handleSubmit, values, form }) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <StyledH1>Dodaj kody promocyjne</StyledH1>
                            <Field
                                name="name"
                                render={({ input, meta }) => (
                                    <>
                                        <Input {...input} placeholder="Nazwa" />
                                        {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                    </>
                                )}
                            />
                            <CheckBoxes>
                                <div>
                                    <Field
                                        name="isReusable"
                                        type="radio"
                                        value="0"
                                        render={({ input }) => (
                                            <>
                                                <Input {...input} id={`${input.name}-y`} />
                                                <label htmlFor={`${input.name}-y`}>Kod jednorazowy</label>
                                            </>
                                        )}
                                    />
                                </div>

                                <div>
                                    <Field
                                        name="isReusable"
                                        type="radio"
                                        value="1"
                                        render={({ input }) => (
                                            <>
                                                <Input {...input} id={`${input.name}-n`} />
                                                <label htmlFor={`${input.name}-n`}>Kod wielorazowy</label>
                                            </>
                                        )}
                                    />
                                </div>

                                <div>
                                    <Field
                                        name="isInfinite"
                                        type="radio"
                                        value="1"
                                        render={({ input }) => (
                                            <>
                                                <Input
                                                    {...input}
                                                    id={`${input.name}-y`}
                                                    onChange={(e) => {
                                                        input.onChange(e);
                                                        form.change('days');
                                                    }}
                                                />
                                                <label htmlFor={`${input.name}-y`}>Ważność nieskończona</label>
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name="isInfinite"
                                        type="radio"
                                        value="0"
                                        render={({ input }) => (
                                            <>
                                                <Input {...input} id={`${input.name}-n`} />
                                                <label htmlFor={`${input.name}-n`}>.</label>
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name="days"
                                        type="number"
                                        render={({ input, meta }) => (
                                            <>
                                                <Input
                                                    {...input}
                                                    placeholder="Ilość dni"
                                                    disabled={values.isInfinite === '1' ? 'disabled' : false}
                                                />
                                                {meta.error && meta.touched && (
                                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                                )}
                                            </>
                                        )}
                                    />
                                </div>
                            </CheckBoxes>

                            <Dates>
                                <Field
                                    name="from"
                                    type="date"
                                    render={({ input, meta }) => (
                                        <>
                                            <Input {...input} placeholder="Nazwa" />
                                            {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                        </>
                                    )}
                                />
                                <Field
                                    name="to"
                                    type="date"
                                    render={({ input, meta }) => (
                                        <>
                                            <Input {...input} placeholder="Nazwa" />
                                            {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                        </>
                                    )}
                                />
                            </Dates>

                            <p>Produkty objęte promocją</p>

                            <div>
                                <Field
                                    name="isAllProducts"
                                    type="radio"
                                    value="1"
                                    render={({ input }) => (
                                        <>
                                            <Input {...input} id={`${input.name}-y`} />
                                            <label htmlFor={`${input.name}-y`}>Wszystkie</label>
                                        </>
                                    )}
                                />
                            </div>

                            <div>
                                <Field
                                    name="isAllProducts"
                                    type="radio"
                                    value="0"
                                    render={({ input }) => (
                                        <>
                                            <Input {...input} id={`${input.name}-n`} />
                                            <label htmlFor={`${input.name}-n`}>Wybrane</label>
                                        </>
                                    )}
                                />
                            </div>

                            {values.isAllProducts === '0' && (
                                <>
                                    <FieldArray name="devices">
                                        {({ fields }) => (
                                            <StyledDevicesWrapper>
                                                {fields.map((promoName, index) => (
                                                    <>
                                                        <PromoItem
                                                            categories={categories}
                                                            name={promoName}
                                                            index={index}
                                                        />
                                                    </>
                                                ))}

                                                <FontAwesomeIconPlus
                                                    onClick={() => fields.push({ categoryId: null, itemId: null })}
                                                    icon={faPlus}
                                                />
                                            </StyledDevicesWrapper>
                                        )}
                                    </FieldArray>
                                </>
                            )}

                            <Field
                                name="codes"
                                render={({ input, meta }) => (
                                    <>
                                        <TextArea {...input} placeholder="Wpisz kody - jeden pod drugim" rows={20} />
                                        {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                    </>
                                )}
                            />
                            <Button>Zapisz</Button>
                        </StyledForm>
                    )}
                />
            </PanelContentWrapper>
        </>
    );
};
