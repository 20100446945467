import React from 'react';
import { Field, Form } from 'react-final-form';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { apiUrl } from '../../api';
import { Button, ErrorMessage, Input, StyledForm, StyledH1 } from '../../components/FormComponents';
import PanelContentWrapper from '../../components/PanelContentWrapper';

export const ChangePassword = () => {
    const token = useSelector((store) => store.token);

    return (
        <>
            <PanelContentWrapper>
                <Form
                    onSubmit={(values, form) => {
                        axios
                            .post(`${apiUrl}/changePassword`, values, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            })
                            .then(() => {
                                NotificationManager.success('Zmieniono hasło');
                                form.reset();
                            })
                            .catch(() => {
                                NotificationManager.error('spróbuj ponownie', 'Błąd ');
                            });
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.oldPassword) {
                            errors.oldPassword = 'Podaj obecne hasło';
                        }
                        if (!values.password) {
                            errors.password = 'Podaj hasło';
                        }
                        if (!values.password_confirmation) {
                            errors.password_confirmation = 'Powtórz hasło';
                        }
                        if (
                            values.password_confirmation &&
                            values.password &&
                            values.password !== values.password_confirmation
                        ) {
                            errors.password = 'Podane hasła są różne';
                        }
                    }}
                    render={({ handleSubmit, submitting }) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <div>
                                <StyledH1>Zmiana hasła</StyledH1>
                                <Field
                                    name="oldPassword"
                                    type="password"
                                    render={({ input, meta }) => (
                                        <>
                                            <Input {...input} placeholder="Obecne hasło" />
                                            {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                        </>
                                    )}
                                />
                                <Field
                                    name="password"
                                    type="password"
                                    render={({ input, meta }) => (
                                        <>
                                            <Input {...input} placeholder="Hasło" />
                                            {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                        </>
                                    )}
                                />
                                <Field
                                    name="password_confirmation"
                                    type="password"
                                    render={({ input, meta }) => (
                                        <>
                                            <Input {...input} placeholder="Powtórz hasło" />
                                            {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                        </>
                                    )}
                                />
                            </div>
                            <Button disabled={submitting}>Zapisz</Button>
                        </StyledForm>
                    )}
                />
            </PanelContentWrapper>
        </>
    );
};
