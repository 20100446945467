import styled from 'styled-components';

export const Table = styled.table`
    th {
        text-align: left;
    }
    td:last-child {
        text-align: right;
    }
    th,
    td {
        border-bottom: 1px solid #c4c4c4;
        padding: 10px 0;
    }
    width: 100%;
`;
