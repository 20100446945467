import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Pagination from '@material-ui/lab/Pagination';
import { apiUrl } from '../../api';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { Button, SearchInput } from '../../components/FormComponents';
import { StyledLink } from '../../components/Buttons';
import { Table } from '../../components/Table';
import { Modal, ModalButtons } from '../../components/Modal';
import useDebounce from '../../hooks/debounce';
import { Permission } from '../../hooks/Permission';

const StyledTopWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    input {
        margin-right: 15px;
    }
`;

export const AcceptanceList = () => {
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const token = useSelector((store) => store.token);
    const [acceptances, setAcceptances] = useState([]);
    const [modalId, setModalId] = useState(null);
    const [change, setChange] = useState(0);

    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 1000, setPage);

    useEffect(() => {
        axios
            .get(`${apiUrl}/acceptance`, {
                params: {
                    page,
                    search: debouncedSearchTerm,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { acceptances: newAcceptances, lastPage } }) => {
                setAcceptances(newAcceptances);
                setPages(lastPage);
            });
    }, [token, page, change, debouncedSearchTerm]);

    return (
        <>
            <Permission permission="acceptance-index" />
            <PanelContentWrapper>
                <StyledTopWrapper>
                    <SearchInput onChange={(e) => setSearchTerm(e.target.value)} />
                    <StyledLink to="/zgody/dodaj">Dodaj zgodę</StyledLink>
                </StyledTopWrapper>
                <Table>
                    <thead>
                        <tr>
                            <th>Nazwa</th>
                            <th>Kryterium</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {acceptances.map(({ id, name, isObligatory }) => (
                            <tr key={id}>
                                <td>{name}</td>
                                <td>{isObligatory}</td>
                                <td>
                                    <FontAwesomeIcon
                                        onClick={() => {
                                            setModalId(id);
                                        }}
                                        icon={faTrash}
                                        fixedWidth
                                    />
                                    <Link to={`/zgody/${id}`}>
                                        <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Pagination
                    count={pages}
                    page={page}
                    size="large"
                    shape="rounded"
                    onChange={(event, pageNumber) => {
                        setPage(pageNumber);
                    }}
                />
            </PanelContentWrapper>
            {modalId && (
                <Modal
                    closeModal={() => {
                        setModalId(null);
                    }}
                >
                    <p>Czy na pewno usunąć zgodę?</p>
                    <ModalButtons>
                        <Button
                            onClick={() => {
                                axios
                                    .delete(`${apiUrl}/acceptance/${modalId}`, {
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                        },
                                    })
                                    .then(() => {
                                        setChange(change + 1);
                                        setModalId(null);
                                    });
                            }}
                        >
                            Tak
                        </Button>
                        <Button
                            secondary
                            onClick={() => {
                                setModalId(null);
                            }}
                        >
                            Nie
                        </Button>
                    </ModalButtons>
                </Modal>
            )}
        </>
    );
};
