import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { AdminForm } from './AdminForm';
import { apiUrl } from '../../api';
import { Permission } from '../../hooks/Permission';

export const AdminCreate = () => {
    const token = useSelector((store) => store.token);
    const [redirect, setRedirect] = useState(null);

    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        axios
            .get(`${apiUrl}/permission`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { permissions: newPermissions } }) => {
                setPermissions(newPermissions);
            });
    }, [token]);

    return (
        <>
            <Permission permission="admin-store" />
            {redirect && <Redirect to={redirect} />}
            <PanelContentWrapper>
                <AdminForm
                    initialValues={{
                        permissions,
                    }}
                    onSubmit={(values) => {
                        axios
                            .post(`${apiUrl}/user`, values, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            })
                            .then(
                                ({
                                    data: {
                                        user: { id },
                                    },
                                }) => {
                                    setRedirect(`/administratorzy/${id}`);
                                    NotificationManager.success('Dodano administratora');
                                },
                            )
                            .catch(() => {
                                NotificationManager.error('spróbuj ponownie', 'Błąd ');
                            });
                    }}
                />
            </PanelContentWrapper>
        </>
    );
};
