import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Permission } from '../../hooks/Permission';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { apiUrl } from '../../api';

export const PromoCodeContracts = () => {
    const { id } = useParams();
    const [contracts, setContracts] = useState([]);
    const token = useSelector((store) => store.token);

    useEffect(() => {
        axios
            .get(`${apiUrl}/promoCode/${id}/contracts`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { contracts: newItems } }) => {
                setContracts(newItems);
            });
    }, [id, token]);

    return (
        <>
            <Permission permission="promoCode-index" />
            <PanelContentWrapper>
                {contracts.map(({ id: cId,contractNumber, email, name, surname, companyName, pesel }) => (
                    <div>
                        <p>
                            ID: <Link to={`/umowy/${cId}`}>{contractNumber ?? cId}</Link>
                        </p>
                        <p>E-mail: {email}</p>
                        <p>Imię: {name}</p>
                        <p>Nazwisko: {surname}</p>
                        {companyName && (
                            <p>Nazwa firmy: {companyName}</p>
                        )}
                        <p>Pesel: {pesel}</p>
                    </div>
                ))}
            </PanelContentWrapper>
        </>
    );
};
