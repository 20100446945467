import styled from 'styled-components';
import { H1 } from './Headings';

export const LoginWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 240px);
    width: 100%;
    margin: 90px auto 20px;
    position: relative;
`;
export const StyledH1 = styled(H1)`
    margin-bottom: 60px;
`;
export const StyledImg = styled.img`
    align-self: flex-end;
    position: absolute;
    bottom: 0;
`;
export const StyledForm = styled.form`
    width: 500px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 260px;
`;
export const CheckBoxAndLink = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
`;
