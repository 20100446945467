import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { PromoProductForm } from './PromoProductForm';
import { apiUrl } from '../../api';
import { Permission } from '../../hooks/Permission';

export const PromoProductEdit = () => {
    const match = useRouteMatch();
    const token = useSelector((store) => store.token);
    const [item, setItem] = useState({});

    useEffect(() => {
        axios
            .get(`${apiUrl}/promoItem/${match.params.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { item: newItem } }) => {
                setItem(newItem);
            });
    }, [token, match.params.id]);

    return (
        <>
            <Permission permission="promoItem-update" />
            <PanelContentWrapper>
                <PromoProductForm
                    onSubmit={(values) => {
                        axios
                            .put(`${apiUrl}/promoItem/${match.params.id}`, values, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            })
                            .then(() => {
                                NotificationManager.success('Zapisano zmiany');
                            })
                            .catch(() => {
                                NotificationManager.error('spróbuj ponownie', 'Błąd ');
                            });
                    }}
                    initialValues={item}
                />
            </PanelContentWrapper>
        </>
    );
};
