import React, { useEffect, useState } from 'react';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT } from '../actions';
import { AdminList } from './Admin/AdminList';
import { AdminEdit } from './Admin/AdminEdit';
import { AdminCreate } from './Admin/AdminCreate';
import { PromoProductsList } from './Promo/PromoProductsList';
import { PromoCategoryCreate } from './Promo/PromoCategoryCreate';
import { PromoProductCreate } from './Promo/PromoProductCreate';
import { PromoProductEdit } from './Promo/PromoProductEdit';
import { PhoneNumberList } from './PhoneNumber/PhoneNumberList';
import { PhoneNumberCreate } from './PhoneNumber/PhoneNumberCreate';
import { AcceptanceList } from './Acceptance/AcceptanceList';
import { AcceptanceCreate } from './Acceptance/AcceptanceCreate';
import { AcceptanceEdit } from './Acceptance/AcceptanceEdit';
import { DeviceList } from './Device/DeviceList';
import { DeviceCreate } from './Device/DeviceCreate';
import { DeviceEdit } from './Device/DeviceEdit';
import { AgreementList } from './Agreement/AgreementList';
import { OfferList } from './Offer/OfferList';
import { OfferEdit } from './Offer/OfferEdit';
import { PromoCodeList } from './Promo/PromoCodeList';
import { PromoCodeCreate } from './Promo/PromoCodeCreate';
import { ApiConfig } from './ApiConfig/ApiConfig';
import { Contract } from './Contracts/Contract';
import { ContractList } from './Contracts/ContractList';
import { H1 } from '../components/Headings';
import PanelContentWrapper from '../components/PanelContentWrapper';
import { PromoCategoryList } from './PromoCategory/PromoCategoryList';
import { ChangePassword } from './AuthUser/ChangePassword';
import { PromotionList } from './Promo/PromotionList';
import { PromotionEdit } from './Promo/PromotionEdit';
import { PromoCodeContracts } from './Promo/PromoCodeContracts';

const StyledMenuWrapper = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: flex-start;
    z-index: 1;
`;
const StyledMenu = styled.ul`
    background: ${({ theme: { menuBackground } }) => menuBackground};
    width: 230px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
`;
const StyledMain = styled.main`
    position: relative;
    min-height: calc(100vh - 130px);
    overflow: hidden;
`;
const StyledLink = styled(Link)`
    text-decoration: none;
    margin: 25px 0 0 20px;
    display: block;
    ${({ isactive }) =>
        !!isactive &&
        css`
            font-weight: 600;
        `}
`;
const StyledContentWrapper = styled.div`
    transition: transform 0.3s ease-in-out;
    margin-left: 230px;
`;

const StyledPanelContentWrapper = styled(PanelContentWrapper)`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const PanelWrapper = () => {
    const [redirect, setRedirect] = useState(null);
    const location = useLocation();

    const token = useSelector(({ token: t }) => t);
    const permissions = useSelector(({ permissions: p }) => p);
    const expiresIn = useSelector(({ expiresIn: e }) => e);
    const dispatch = useDispatch();

    useEffect(() => {
        // setMenuOpened(false);
        const interval = setInterval(() => {
            if (!token || expiresIn <= new Date().getTime()) {
                dispatch({ type: LOGOUT });
                setRedirect('/');
            }
        }, 10000);
        if (!token || expiresIn <= new Date().getTime()) {
            dispatch({ type: LOGOUT });
            setRedirect('/');
        }
        return () => {
            clearInterval(interval);
        };
    }, [location, token, expiresIn, dispatch]);

    return (
        <>
            {redirect && <Redirect to={redirect} />}
            <StyledMain>
                <StyledMenuWrapper>
                    <StyledMenu>
                        {permissions.includes('contract-index') && (
                            <li>
                                <StyledLink to="/umowy" isactive={location.pathname === '/umowy'}>
                                    Umowy
                                </StyledLink>
                            </li>
                        )}
                        {permissions.includes('offer-index') && (
                            <li>
                                <StyledLink to="/oferty" isactive={location.pathname === '/oferty'}>
                                    Oferty
                                </StyledLink>
                            </li>
                        )}
                        {permissions.includes('device-index') && (
                            <li>
                                <StyledLink to="/urzadzenia" isactive={location.pathname === '/urzadzenia'}>
                                    Urządzenia
                                </StyledLink>
                            </li>
                        )}
                        {permissions.includes('promoCode-index') && (
                            <>
                                <li>
                                    <StyledLink to="/promocje" isactive={location.pathname === '/promocje'}>
                                        Promocje
                                    </StyledLink>
                                </li>
                                <li>
                                    <StyledLink
                                        to="/kody-promocyjne"
                                        isactive={location.pathname === '/kody-promocyjne'}
                                    >
                                        Kody promocyjne
                                    </StyledLink>
                                </li>
                            </>
                        )}
                        {permissions.includes('promoItem-index') && (
                            <li>
                                <StyledLink
                                    to="/produkty-promocyjne"
                                    isactive={location.pathname === '/produkty-promocyjne'}
                                >
                                    Produkty promocyjne
                                </StyledLink>
                            </li>
                        )}
                        {permissions.includes('promoCategory-index') && (
                            <li>
                                <StyledLink
                                    to="/produkty-promocyjne/kategorie"
                                    isactive={location.pathname === '/produkty-promocyjne/kategorie'}
                                >
                                    Produkty promocyjne - kategorie
                                </StyledLink>
                            </li>
                        )}
                        {permissions.includes('phoneNumber-index') && (
                            <li>
                                <StyledLink to="/numery-telefonow" isactive={location.pathname === '/numery-telefonow'}>
                                    Numery telefonów
                                </StyledLink>
                            </li>
                        )}
                        {permissions.includes('acceptance-index') && (
                            <li>
                                <StyledLink to="/zgody" isactive={location.pathname === '/zgody'}>
                                    Zgody
                                </StyledLink>
                            </li>
                        )}
                        {permissions.includes('content-index') && (
                            <li>
                                <StyledLink to="/tresci" isactive={location.pathname === '/tresci'}>
                                    Umowa / Maile
                                </StyledLink>
                            </li>
                        )}
                        {permissions.includes('api-index') && (
                            <li>
                                <StyledLink to="/api" isactive={location.pathname === '/api'}>
                                    API
                                </StyledLink>
                            </li>
                        )}
                        {permissions.includes('admin-index') && (
                            <li>
                                <StyledLink to="/administratorzy" isactive={location.pathname === '/administratorzy'}>
                                    Administratorzy
                                </StyledLink>
                            </li>
                        )}
                        <li>
                            <StyledLink to="/zmien-haslo" isactive={location.pathname === '/zmien-haslo'}>
                                Zmiana hasła
                            </StyledLink>
                        </li>
                    </StyledMenu>
                </StyledMenuWrapper>
                <StyledContentWrapper>
                    <Switch>
                        <Route
                            path="/dashboard"
                            exact
                            component={() => (
                                <StyledPanelContentWrapper>
                                    <H1>WITAJ W PANELU ZARZĄDZANIA</H1>
                                </StyledPanelContentWrapper>
                            )}
                        />
                        <Route path="/umowy" exact component={ContractList} />
                        <Route path="/umowy/:id" exact component={Contract} />
                        <Route path="/api" exact component={ApiConfig} />
                        <Route path="/administratorzy" exact component={AdminList} />
                        <Route path="/administratorzy/dodaj" exact component={AdminCreate} />
                        <Route path="/administratorzy/:id" exact component={AdminEdit} />
                        <Route path="/produkty-promocyjne" exact component={PromoProductsList} />
                        <Route path="/produkty-promocyjne/dodaj" exact component={PromoProductCreate} />
                        <Route path="/produkty-promocyjne/kategorie" exact component={PromoCategoryList} />
                        <Route path="/produkty-promocyjne/:id" exact component={PromoProductEdit} />
                        <Route path="/produkty-promocyjne/kategorie/dodaj" exact component={PromoCategoryCreate} />
                        <Route path="/numery-telefonow/" exact component={PhoneNumberList} />
                        <Route path="/numery-telefonow/dodaj" exact component={PhoneNumberCreate} />
                        <Route path="/zgody" exact component={AcceptanceList} />
                        <Route path="/zgody/dodaj" exact component={AcceptanceCreate} />
                        <Route path="/zgody/:id" exact component={AcceptanceEdit} />
                        <Route path="/urzadzenia" exact component={DeviceList} />
                        <Route path="/urzadzenia/dodaj" exact component={DeviceCreate} />
                        <Route path="/urzadzenia/:id" exact component={DeviceEdit} />
                        <Route path="/tresci" exact component={AgreementList} />
                        <Route path="/oferty" exact component={OfferList} />
                        {/* <Route path="/oferty/dodaj" exact component={OfferCreate}/> */}
                        <Route path="/oferty/edytuj/:categoryId/:typeId" exact component={OfferEdit} />
                        <Route path="/kody-promocyjne" exact component={PromoCodeList} />
                        <Route path="/kody-promocyjne/:id/umowy" exact component={PromoCodeContracts} />
                        <Route path="/kody-promocyjne/dodaj" exact component={PromoCodeCreate} />
                        <Route path="/zmien-haslo" exact component={ChangePassword} />
                        <Route path="/promocje" exact component={PromotionList} />
                        <Route path="/promocje/:id" exact component={PromotionEdit} />
                    </Switch>
                </StyledContentWrapper>
            </StyledMain>
        </>
    );
};
