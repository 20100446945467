import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Redirect, Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faTrash } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Pagination from '@material-ui/lab/Pagination';
import { Field, Form } from 'react-final-form';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import queryString from 'query-string';
import { Table } from '../../components/Table';
import { Button, Input, SearchInput } from '../../components/FormComponents';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { apiUrl } from '../../api';
import useDebounce from '../../hooks/debounce';
import { Permission } from '../../hooks/Permission';
import { Modal, ModalButtons } from '../../components/Modal';

const StyledTopWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    input {
        margin-right: 15px;
    }
`;

export const ContractList = () => {
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(null);
    const token = useSelector((store) => store.token);
    const [contracts, setContracts] = useState([]);
    const [modalId, setModalId] = useState(null);
    const [modal, setModal] = useState(null);
    const [change, setChange] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [orderBy, setOrderBy] = useState('id');
    const [orderDir, setOrderDir] = useState('desc');
    const [redirect, setRedirect] = useState(null);

    const debouncedSearchTerm = useDebounce(searchTerm, 1000, setPage);

    const location = useLocation();

    useEffect(() => {
        const parsed = queryString.parse(location.search);
        if (parsed.page && parseInt(parsed.page, 10) !== page) {
            setPage(parseInt(parsed.page, 10));
        } else if (page) {
            axios
                .get(`${apiUrl}/contract`, {
                    params: {
                        page,
                        search: debouncedSearchTerm,
                        orderBy,
                        orderDir,
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(({ data: { contracts: newContracts, lastPage } }) => {
                    setContracts(newContracts);
                    setPages(lastPage);
                });
        }
    }, [token, page, debouncedSearchTerm, change, orderBy, orderDir]);

    useEffect(() => {
        const parsed = queryString.parse(location.search);
        if (parsed.page) {
            setPage(parsed.page);
        } else {
            setPage(1);
        }
    }, [location]);

    const toggleDir = () => {
        setOrderDir((state) => (state === 'desc' ? 'asc' : 'desc'));
    };

    const changeOrder = (newOrderBy) => {
        if (newOrderBy === orderBy) {
            toggleDir();
        } else {
            setOrderBy(newOrderBy);
        }
    };

    return (
        <>
            {redirect && <Redirect to={redirect} push />}
            <Permission permission="contract-index" />
            <PanelContentWrapper>
                <StyledTopWrapper>
                    <SearchInput onChange={(e) => setSearchTerm(e.target.value)} />
                </StyledTopWrapper>
                <Form
                    onSubmit={(values) => {
                        axios
                            .delete(`${apiUrl}/contract/massDestroy`, {
                                params: values,
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            })
                            .then(() => {
                                setChange(change + 1);
                                setModal(null);
                            });
                    }}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Button type="button" onClick={() => {
                                setModal(true);
                            }}>
                                Usuń zaznaczone
                            </Button>
                            <Table>
                                <thead>
                                    <tr>
                                        <th />
                                        <th
                                            onClick={() => {
                                                changeOrder('contractNumber');
                                            }}
                                        >
                                            Numer umowy
                                            {orderBy === 'contractNumber' && (
                                                <>
                                                    {orderDir === 'desc' ? (
                                                        <>
                                                            <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <FontAwesomeIcon icon={faAngleUp} fixedWidth />
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </th>
                                        <th
                                            onClick={() => {
                                                changeOrder('created_at');
                                            }}
                                        >
                                            Data/Godzina
                                            {orderBy === 'created_at' && (
                                                <>
                                                    {orderDir === 'desc' ? (
                                                        <>
                                                            <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <FontAwesomeIcon icon={faAngleUp} fixedWidth />
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </th>
                                        <th
                                            onClick={() => {
                                                changeOrder('name');
                                            }}
                                        >
                                            Imię i nazwisko/Firma
                                            {orderBy === 'name' && (
                                                <>
                                                    {orderDir === 'desc' ? (
                                                        <>
                                                            <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <FontAwesomeIcon icon={faAngleUp} fixedWidth />
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </th>
                                        <th
                                            onClick={() => {
                                                changeOrder('offerType');
                                            }}
                                        >
                                            Oferta
                                            {orderBy === 'offerType' && (
                                                <>
                                                    {orderDir === 'desc' ? (
                                                        <>
                                                            <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <FontAwesomeIcon icon={faAngleUp} fixedWidth />
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </th>
                                        <th
                                            onClick={() => {
                                                changeOrder('promoCode');
                                            }}
                                        >
                                            Użyty kod
                                            {orderBy === 'promoCode' && (
                                                <>
                                                    {orderDir === 'desc' ? (
                                                        <>
                                                            <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <FontAwesomeIcon icon={faAngleUp} fixedWidth />
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </th>
                                        <th
                                            onClick={() => {
                                                changeOrder('status');
                                            }}
                                        >
                                            Status
                                            {orderBy === 'status' && (
                                                <>
                                                    {orderDir === 'desc' ? (
                                                        <>
                                                            <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <FontAwesomeIcon icon={faAngleUp} fixedWidth />
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {contracts.map(({ id, contractNumber, dateTime, fullName, offerType, promoCode, status, codeId }) => (
                                        <tr>
                                            <td>
                                                <Field
                                                    name="ids"
                                                    type="checkbox"
                                                    value={id}
                                                    render={({ input }) => (
                                                        <>
                                                            <Input {...input} id={`ids-${id}`} />
                                                            <label htmlFor={`ids-${id}`} />
                                                        </>
                                                    )}
                                                />
                                            </td>
                                            <td>{contractNumber}</td>
                                            <td>{dateTime}</td>
                                            <td>{fullName}</td>
                                            <td>{offerType}</td>
                                            <td>
                                                {promoCode && (
                                                    <>
                                                        <Link to={`kody-promocyjne/${codeId}/umowy`}>{promoCode}</Link>
                                                    </>
                                                )}
                                            </td>
                                            <td>{status}</td>
                                            <td>
                                                <Link to={`/umowy/${id}`}>
                                                    <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                                                </Link>
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    fixedWidth
                                                    onClick={() => {
                                                        setModalId(id);
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <Pagination
                                count={pages}
                                page={page}
                                size="large"
                                shape="rounded"
                                onChange={(event, pageNumber) => {
                                    setRedirect(`/umowy?page=${pageNumber}`);
                                }}
                            />

                            {modal && (
                                <Modal
                                    closeModal={() => {
                                        setModal(null);
                                    }}
                                >
                                    <p>Czy na pewno usunąć zaznaczone umowy?</p>
                                    <ModalButtons>
                                        <Button
                                            type="submit"
                                        >
                                            Tak
                                        </Button>
                                        <Button
                                            secondary
                                            onClick={() => {
                                                setModal(null);
                                            }}
                                        >
                                            Nie
                                        </Button>
                                    </ModalButtons>
                                </Modal>
                            )}
                        </form>
                    )}
                />
            </PanelContentWrapper>

            {modalId && (
                <Modal
                    closeModal={() => {
                        setModalId(null);
                    }}
                >
                    <p>Czy na pewno usunąć?</p>
                    <ModalButtons>
                        <Button
                            onClick={() => {
                                axios
                                    .delete(`${apiUrl}/contract/${modalId}`, {
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                        },
                                    })
                                    .then(() => {
                                        setChange(change + 1);
                                        setModalId(null);
                                    });
                            }}
                        >
                            Tak
                        </Button>
                        <Button
                            secondary
                            onClick={() => {
                                setModalId(null);
                            }}
                        >
                            Nie
                        </Button>
                    </ModalButtons>
                </Modal>
            )}
        </>
    );
};
