import { LOGIN, LOGOUT, FETCH_ERROR } from '../actions';

const initialState = {
    token: localStorage.getItem('token') || '',
    permissions: localStorage.getItem('permissions') || '',
    expiresIn: localStorage.getItem('expiresIn') || 0,
    error: '',
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            // eslint-disable-next-line no-case-declarations
            const { token, expiresIn, role, permissions } = action.payload;
            localStorage.setItem('token', token);
            localStorage.setItem('expiresIn', expiresIn);
            localStorage.setItem('permissions', permissions);
            return { ...state, token, expiresIn, role, permissions };
        case FETCH_ERROR:
            return { ...state, error: action.payload };
        case LOGOUT:
            localStorage.removeItem('token');
            localStorage.removeItem('expiresIn');
            localStorage.removeItem('permissions');
            return { ...state, token: '', permissions: '', expiresIn: 0 };
        default:
            return state;
    }
};

export default rootReducer;
