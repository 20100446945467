import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,700;1,700&display=swap');
*,*::before,*::after{
box-sizing: border-box;
margin:0;
padding: 0;
font-family: 'Poppins', sans-serif;
}
html {
  /* 1rem = 10px */
  font-size: 62.5%;
}
body {
  font-size: 1.6rem;
}
#root{
min-height: 100vh;
}
a{
font-size: 1.4rem;
color: #000;
text-decoration: none;
font-weight: 300;
}
`;

export default GlobalStyles;
