import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { DeviceForm } from './DeviceForm';
import { apiUrl } from '../../api';
import { createFormData } from '../../utils';
import { Permission } from '../../hooks/Permission';

export const DeviceCreate = () => {
    const token = useSelector((store) => store.token);
    const [redirect, setRedirect] = useState(null);

    return (
        <>
            <Permission permission="device-store" />
            {redirect && <Redirect to={redirect} />}
            <PanelContentWrapper>
                <DeviceForm
                    onSubmit={(values) => {
                        const fd = new FormData();
                        Object.keys(values).forEach((value) => {
                            createFormData(fd, value, values[value]);
                        });
                        axios
                            .post(`${apiUrl}/device`, fd, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            })
                            .then(
                                ({
                                    data: {
                                        device: { id },
                                    },
                                }) => {
                                    setRedirect(`/urzadzenia/${id}`);
                                    NotificationManager.success('Dodano urządzenie');
                                },
                            )
                            .catch(() => {
                                NotificationManager.error('spróbuj ponownie', 'Błąd ');
                            });
                    }}
                />
            </PanelContentWrapper>
        </>
    );
};
