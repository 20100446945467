import React from 'react';
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { Button, ErrorMessage, Input, StyledForm, StyledH1 } from '../../components/FormComponents';

const Permissions = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    ${StyledH1} {
        grid-column: 1/3;
    }
`;

export const AdminForm = ({ onSubmit, initialValues, id }) => {
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            mutators={{
                // potentially other mutators could be merged here
                ...arrayMutators,
            }}
            validate={(values) => {
                const errors = {};
                if (!values.name) {
                    errors.name = 'Podaj nazwę';
                }
                if (!values.email) {
                    errors.email = 'Podaj email';
                }
                if (!id && !values.password) {
                    errors.password = 'Podaj hasło';
                }
                if (!id && !values.password_confirmation) {
                    /* eslint-disable camelcase */
                    errors.password_confirmation = 'Podaj hasło';
                }
                if (!id && !values.password) {
                    errors.password = 'Podaj hasło';
                }
                if (
                    values.password &&
                    values.password_confirmation &&
                    values.password !== values.password_confirmation
                ) {
                    errors.password = 'Hasła są różne';
                }

                return errors;
            }}
            render={({ handleSubmit }) => (
                <StyledForm onSubmit={handleSubmit}>
                    <div>
                        <StyledH1>{id ? 'Edytuj administratora' : 'Dodaj administratora'}</StyledH1>
                        <Field
                            name="name"
                            render={({ input, meta }) => (
                                <>
                                    <Input {...input} placeholder="Nazwa" />
                                    {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                </>
                            )}
                        />
                        <Field
                            name="email"
                            type="email"
                            render={({ input, meta }) => (
                                <>
                                    <Input {...input} placeholder="E-mail" />
                                    {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                </>
                            )}
                        />
                        {!id && (
                            <>
                                <Field
                                    name="password"
                                    type="password"
                                    render={({ input, meta }) => (
                                        <>
                                            <Input {...input} placeholder="Hasło" />
                                            {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                        </>
                                    )}
                                />
                                <Field
                                    name="password_confirmation"
                                    type="password"
                                    render={({ input, meta }) => (
                                        <>
                                            <Input {...input} placeholder="Powtórz hasło" />
                                            {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                        </>
                                    )}
                                />
                            </>
                        )}
                    </div>

                    <Permissions>
                        <StyledH1>Uprawnienia</StyledH1>
                        <FieldArray name="permissions">
                            {({ fields }) => (
                                <>
                                    {fields.map((permissionName) => (
                                        <div>
                                            <Field
                                                name={`${permissionName}.id`}
                                                type="hidden"
                                                render={({ input }) => (
                                                    <>
                                                        <Input {...input} />
                                                    </>
                                                )}
                                            />
                                            <Field
                                                name={`${permissionName}.isSelected`}
                                                type="checkbox"
                                                render={({ input }) => (
                                                    <>
                                                        <Input {...input} id={`${permissionName}-n`} />
                                                    </>
                                                )}
                                            />
                                            <Field
                                                name={`${permissionName}.name`}
                                                render={({ input }) => (
                                                    <>
                                                        <label htmlFor={`${permissionName}-n`}>{input.value}</label>
                                                    </>
                                                )}
                                            />
                                        </div>
                                    ))}
                                </>
                            )}
                        </FieldArray>
                    </Permissions>
                    <Button>Zapisz</Button>
                </StyledForm>
            )}
        />
    );
};
