import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 600;
    background-color: ${({ theme: { orange } }) => orange};
    color: #fff;
`;
