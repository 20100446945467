import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { OfferForm } from './OfferForm';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { apiUrl } from '../../api';
import { Permission } from '../../hooks/Permission';

export const OfferEdit = () => {
    const token = useSelector((store) => store.token);
    const match = useRouteMatch();
    const [initialValues, setInitialValues] = useState([]);
    const [devices, setDevices] = useState([]);
    const [pdf, setPdf] = useState(null);

    useEffect(() => {
        axios
            .get(`${apiUrl}/offer`, {
                params: {
                    categoryId: match.params.categoryId,
                    typeId: match.params.typeId,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { packageTypes, devices: newDevices, pdf: newPdf } }) => {
                setInitialValues(packageTypes);
                setDevices(newDevices);
                setPdf(newPdf ? { pdfUrl: newPdf.pdfUrl, pdfName: newPdf.path, updatedAt: newPdf.updated_at } : null);
            });
    }, [token, match.params.categoryId, match.params.typeId]);

    return (
        <>
            <Permission permission="offer-update" />
            <PanelContentWrapper>
                <OfferForm
                    onSubmit={(values) => {
                        axios
                            .post(`${apiUrl}/offer`, values, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            })
                            .then(() => {
                                NotificationManager.success('Zapisano zmiany');
                            })
                            .catch(() => {
                                NotificationManager.error('spróbuj ponownie', 'Błąd ');
                            });
                    }}
                    initialValues={{
                        categoryId: parseInt(match.params.categoryId, 10),
                        typeId: parseInt(match.params.typeId, 10),
                        packageTypes: initialValues,
                        devices,
                        pdfFile: pdf,
                    }}
                />
            </PanelContentWrapper>
        </>
    );
};
