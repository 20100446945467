const isFile = (data) => {
    return data.name && data.size && data.type && data.lastModified;
};

export const createFormData = (formData, key, data) => {
    if (isFile(data)) {
        formData.append(key, data);
    } else if (data === Object(data) || Array.isArray(data)) {
        // eslint-disable-next-line
        for (let i in data) {
            createFormData(formData, `${key  }[${  i  }]`, data[i]);
        }
    } else if (data === false || data === true) {
            formData.append(key, !!data);
        } else {
            formData.append(key, data);
        }
};
