import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { PromoProductForm } from './PromoProductForm';
import { apiUrl } from '../../api';
import { Permission } from '../../hooks/Permission';

export const PromoProductCreate = () => {
    const token = useSelector((store) => store.token);
    const [redirect, setRedirect] = useState(null);

    return (
        <>
            <Permission permission="promoItem-store" />
            {redirect && <Redirect to={redirect} />}
            <PanelContentWrapper>
                <PromoProductForm
                    onSubmit={(values) => {
                        axios
                            .post(`${apiUrl}/promoItem`, values, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            })
                            .then(
                                ({
                                    data: {
                                        item: { id },
                                    },
                                }) => {
                                    setRedirect(`/produkty-promocyjne/${id}`);
                                    NotificationManager.success('Dodano produkt');
                                },
                            )
                            .catch(() => {
                                NotificationManager.error('spróbuj ponownie', 'Błąd ');
                            });
                    }}
                    initialValues={{}}
                />
            </PanelContentWrapper>
        </>
    );
};
