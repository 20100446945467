import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../static/images/logo-fmmobile-wb 1.png';
import { LOGOUT } from '../actions';

const StyledHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 130px;
    width: 100%;
    background-color: ${({ theme: { orange } }) => orange};
    position: relative;
`;

const StyledButton = styled.button`
    background: transparent;
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    color: #fff;
`;

export const Header = () => {
    const token = useSelector(({ token: t }) => t);
    const dispatch = useDispatch();

    return (
        <StyledHeader>
            <img src={Logo} alt="" />
            {!!token && (
                <StyledButton
                    onClick={() => {
                        dispatch({ type: LOGOUT });
                    }}
                >
                    WYLOGUJ
                </StyledButton>
            )}
        </StyledHeader>
    );
};
